@use '~scss/settings' as *;

html,
body,
#root {
  @include size(100%);
}

html body {
  @extend %font;

  @extend %ptm-ncoded-lib-modal;

  margin: 0;
  overscroll-behavior: none;

  // --color-primary: #{$color-primary-blue};
  // --color-secondary: #{#{$color-primary-blue}33};

  --color-primary: #{$color-primary-orange};
  --color-secondary: #{#{$color-primary-orange}33};
  --border-color: #e0e0e0;

  --color-background: #{$color-gray-eee};
  --color-background-container: #{$color-white};
  --color-text: #{$color-gray-333};

  background-color: var(--color-background);

  &.dark {
    --color-primary: #{$color-primary-orange};
    --color-secondary: #{#{$color-primary-orange}33};
    --border-color: #{$color-white};

    --color-background: #{$color-gray-555};
    --color-background-container: #{$color-gray-333};
    --color-text: #{$color-white};
  }
  .ncoded-button--solid {
    --nd-button-color: var(--color-primary);
  }
  h2 {
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  button.ncoded-button {
    @include flex-gap(8px);

    padding: 16px;
    height: fit-content;
    min-width: none;

    svg {
      @include size(20px);

      margin: 0 8px;
    }

    &--outline {
      border-color: var(--color-primary);
      background-color: var(--color-secondary);
      color: var(--color-primary);
    }

    &--secondary {
      background-color: var(--color-secondary);
      color: var(--color-primary);
    }

    &--icon {
      background-color: var(--color-primary);
      color: $color-white;

      width: 40px;
      position: relative;
      height: 0;
      padding: 40px 0 0;

      > *,
      svg {
        position: absolute;
        inset: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}

body,
#root {
  @extend %font;

  &,
  * {
    box-sizing: border-box;
  }

  margin: 0;
  overscroll-behavior: none;

  ::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-text);
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
  }

  ::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-text);
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
  }

  input[type='date' i] {
    font-family: 'Inter';
  }

  .loader-container {
    &__loader {
      border-top-color: $color-primary-orange;
    }
  }

  .MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButtonBase-root.ncoded-button.ncoded-button--outline.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    border: 2px solid currentColor;
    height: 50px;
  }
}
