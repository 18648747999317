@use '~scss/settings' as *;


.dark{
    .toggle-button{
        background-color: white;
    }
}

.toggle-button{
    @include size(60px,30px);

    position:relative;
    border-radius:50px;
    box-shadow: $box-shadow;
    cursor:pointer;

    background-color: #e0e0e0;

    

    > svg{
        @include size(20px);
        
        color:var(--color-text);
        position: absolute;
        top:50%;
        z-index: 5;
        color:white;

        &.svg-checked{
            color:var(--color-primary);

        }

        &:first-of-type{
            left:0;
            transform: translate(5px,-50%);        
        }

        &:last-of-type{
            right:0;     
            transform: translate(-5px,-50%);   
        }
    }
    
    .circle {
        @include size(30px);

        position:absolute;
        inset:0px;
        border-radius: 50%;
        background: $color-primary-orange;
        box-shadow: 0 0 5px $color-primary-orange;
        transition: transform 0.5s;
        
        &--checked{
            transform:translateX(100%);
        }
    }
}