@use './color' as *;

$global-font-size: 16;
$overlay-window-z: 5;
$menu-z-index: 10;
$modal-z-index: 20;
$border-radius: 8px;
$padding-169: 56.25%;
$box-shadow: 0px 4px 6px rgba(0, 0, 83, 0.08);
$component-border: 1px solid $color-gray-ec;
