@use '~scss/settings' as *;

.my-data-grid {
  //   @include size(100%, 100%);

  flex: 1;
  color: var(--color-text);

  &__table.MuiDataGrid-root {
    @include size(100%);
    color: var(--color-text);

    .MuiCheckbox-root {
      color: var(--color-text);
    }
  }

  button {
    & + button {
      margin-left: 20px;
    }
  }
}

.MuiDataGrid-root .custom-toolbar.MuiDataGrid-toolbarContainer {
  padding: 20px;
  border-bottom: 1px var(--border-color) solid;
}

.custom-toolbar {
  .button {
    border: 2px solid $color-primary-orange;
  }
}

.overlay.ncoded-modal {
  form.google-sheet-form {
    width: 100%;

    > div {
      @include flex;
      @include gap(12px);

      width: 100%;

      > div {
        flex: 1;
      }
    }
  }
}
