@use '../breakpoints' as *;
@use '../tools/t-font-size' as *;
@use '../tools/t-flex' as *;

%ptm-modal {
  > .overlay__content {
    > .ncoded-modal__content {
      min-height: unset;

      > header {
        @include flex(row, space-between, flex-start);

        border: none;
        padding: 4px;

        color: var(--color-text);

        &.with-title {
          @include font(18px, 24px, 600);

          padding: 12px;
        }

        > button {
          @include phablet {
            position: absolute;
            top: -8px;
            right: 8px;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 50%;
            padding: 4px;
            box-sizing: content-box;

            transform: translate3d(0, -100%, 0);

            &:action {
              transform: translate3d(0, -100%, 0) scale(0.98);
            }
          }
        }
      }

      @include phablet {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: auto;
        width: 100%;
      }
    }
  }
}

%ptm-ncoded-lib-modal {
  .overlay.ncoded-modal {
    @extend %ptm-modal;
  }
}
