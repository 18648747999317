@use '~scss/settings' as *;
div.MuiDataGrid-viewport {
  overflow: inherit !important;
}
.training-plan-page {
  @include flex(column);
  @include gap(32px, column);

  position: relative;
  height: 100%;

  .dpl-page-content-container {
    flex: 1;
  }

  &__data-grid {
    @include size(100%);
  }

  &-modal__cmp-grid {
    display: flex;
    flex-direction: column;
    z-index: 14;
  }
}

%icon {
  @include size(16px, 4px);
  background-color: $color-white;

  position: relative;

  &::before {
    @include size(100%);

    content: '';
    position: absolute;
    background-color: inherit;
    inset: 0;
    opacity: 0;
    transition: opacity 0.33s;
    transform: rotate(90deg);
  }
}

.icon {
  &--minus {
    @extend %icon;
  }
  &--plus {
    @extend %icon;

    &::before {
      opacity: 1;
    }
  }
}

.connect-grid-modal.overlay {
  .overlay__content {
    .ncoded-modal__content {
      @include size(80%);
      @include flex(column);

      > header {
        display: none;
      }

      > main {
        flex: 1;

        > * {
          flex: 1;
        }
      }
    }
  }
}
