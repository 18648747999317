@use '~scss/settings' as *;

.dpl-training-plan-form {
  background-color: var(--color-background-container);
  border-radius: $border-radius;

  > * + * {
    margin-top: 12px;
    width: 100%;
  }

  .pairInput {
    @include flex;
    @include flex-gap;

    > * {
      width: 100%;
    }
  }

  .tripleInput {
    @include flex;
    @include flex-gap;

    > * {
      width: 100%;
    }
  }
}
