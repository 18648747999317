@use '../breakpoints' as *;
@use '../tools/t-font-size' as *;
@use '../tools/t-flex' as *;
@use '../tools/t-size' as *;
@use '../tools/t-gap' as *;
@use '../color' as *;

%ptm-page-wrapper {
  @include flex(row);
  @include gap(32px, row);

  height: 100%;

  .ptm-page-menu {
    @include flex(column, flex-start, stretch);

    .dropdown {
      width: 100%;

      .dropdown__content {
        min-width: 100px;
      }

      &:first-of-type {
        .dropdown__trigger {
          .ncoded-button {
            border-radius: 8px 0 0 0;
          }
        }
      }

      .dropdown__trigger {
        width: 100%;

        &--open {
          .ncoded-button {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .ncoded-button {
          background-color: transparent;
          color: $color-gray-333;
          border-radius: 0;
          white-space: nowrap;
          width: 100%;

          svg {
            @include size(12px);

            transition: transform 0.33s;
          }

          &:hover {
            background-color: var(--color-secondary);
            color: var(--color-primary);
          }
        }
      }
    }

    ul.ptm-page-list {
      @include gap(12px, column);

      padding: 12px;
      list-style-type: none;

      > li {
        > button {
          position: relative;
          background-color: transparent;
          border: none;
          padding: 0;
          cursor: pointer;
          padding: 4px;

          &:before {
            content: '';
            height: 1px;
            width: 0;
            transition: width 330ms;
            background-color: currentColor;
            position: absolute;
            bottom: 0;
            left: 0;
          }

          &:hover {
            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .dpl-page-content-container {
    @include flex(row);

    flex-grow: 1;
  }
}
