@use '~scss/settings' as *;

.ptm-navigation {
  position: sticky;
  inset: 0 0 0 auto;
  height: 100%;
  width: 225px;
  height: 100%;
  z-index: 2;
  background-color: var(--color-background-container);
  box-shadow: $box-shadow;

  > ul {
    padding: 0;
    margin: 0;

    > li {
      @include font(16px, 20px, 400);

      padding: 0;
      margin: 0;
      white-space: nowrap;

      > a {
        @include flex(row, flex-start, center);
        @include flex-gap(20px);

        padding: 10px 20px;
        text-decoration: none;
        color: var(--color-text);
        cursor: pointer;

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          padding-bottom: 20px;
        }

        &.activeLink {
          background-color: var(--color-secondary);
          color: var(--color-primary);
          border-right: 1px solid var(--color-primary);
          cursor: default;
        }

        &:hover {
          background-color: var(--color-secondary);
        }
      }
    }
  }
  .navigation-bottom-menu {
    > button {
      background-color: transparent;
      color: var(--color-text);

      &:hover {
        color: grey;
      }
    }
  }
}
