$color-white: #fff;
$color-gray-eee: #eee;

$color-primary-orange: #f97b26;
$color-primary-blue: #0000ff;

$color-primary: $color-primary-orange;

$color-gray-333: #333;
$color-gray-555: #555;

$color-gray-22: #222;
$color-gray-44: #444;
$color-gray-48: #484848;
$color-gray-4F: #4f4f4f;
$color-gray-56: #565656;
$color-gray-61: #616161;
$color-gray-66: #666;
$color-gray-82: #828282;
$color-gray-99: #999;
$color-gray-E4: #e4e6e4;
$color-gray-e7: #e7e7e7;
$color-gray-f3: #f2f3f7;
$color-gray-f8: #f2f3f8;
$color-gray-fb: #f9fafb;
$color-gray-b2: #b2b2b2;
$color-gray-bd: #bdbdbd;
$color-gray-aa: #aaa;
$color-gray-e0: #e0e0e0;

$color-gray-ec: #ececec;

$color-red: #ff0000;
