@use '~scss/settings' as *;

#root > div > div > div > div > div.dropdown > div.dropdown__content.dropdown__content--open {
  top: 5% !important;
}

.comparsion-chart {
  @extend %ptm-page-wrapper;

  overflow: hidden;

  &__container {
    @extend %container;
  }

  &__chart {
    @extend %container;

    overflow: scroll;
    width: fit-content;
  }
}

%container {
  background-color: $color-white;
  border-radius: $border-radius;
  padding: 12px;
}
