@use '~scss/settings' as *;

.connect-grid {
  @include size(100%);
  @include flex(column);
  @include flex-gap(20px, column);

  &__container {
    flex: 1;
  }

  &__row {
    @include flex;
    @include flex-gap(12px);
  }

  &__data-grid {
    height: 300px;
  }
}

.cmp-data-grid > ul {
  list-style-type: none;
}

.modal {
  &__buttons {
    @include flex(row, space-around, center);
    @include flex-gap(12px);

    width: 100%;
    height: 60px;
    padding-top: 20px;

    > button.ncoded-button {
      @include font(16px, 20px);

      height: 100%;
      flex: 1;
    }
  }
}

.arrow-back {
  @include size(14px, 2px);

  color: $color-white;
  background-color: currentColor;
  position: relative;
  margin-left: 6px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-100%, -50%, 0);

    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid currentColor;
  }
}
