@use '~scss/settings' as *;

.statistics-page {
  @extend %ptm-page-wrapper;

  &__data-grid {
    @include size(100%);
    .ncoded-button.ncoded-button--outline {
      padding: 0px;
      margin-left: 16px;
      border: 2px solid $color-primary-orange;
      max-height: 50px;
      color: var(--color-primary);
      background-color: var(--color-secondary);
    }
  }

  &.statistics-page__data-grid {
    color: var(--color-text);

    &__table.MuiDataGrid-root {
      @include size(100%);
      color: var(--color-text);

      .MuiCheckbox-root {
        color: var(--color-text);
      }
    }

    button {
      & + button {
        margin-left: 20px;
      }
    }
  }
}

.MuiDataGrid-root .custom-toolbar.MuiDataGrid-toolbarContainer {
  padding: 20px;
  border-bottom: 1px var(--border-color) solid;
}
