@use '~scss/settings' as *;

.yx-input-field {
  @include flex(column);

  & .ncoded-input--has-suffix {
    padding: 0;
  }

  > label {
    @include font(10px, 12px, 400);

    color: var(--color-text);
    text-transform: uppercase;
    margin: 0 0 10px;
  }

  &__unit {
    @include flex(row, center, center);
    @include size(100%);
    @include font(14px, 18px, 400);

    &.ncoded-input--has-suffix {
      padding: 0px;
    }

    max-width: 70px;
    background-color: transparent;
    height: 100%;
    width: fit-content;
    position: relative;
    color: $color-primary-blue;
    padding: 14px;

    &::after {
      @include absolute(left 0);
      @include size(1px, 70%);

      content: '';
      margin: 8px 0px;
      background-color: $color-white;
    }
  }

  &--error {
    > .ncoded-input--error > input {
      background-color: white;
    }

    .yx-input-field__unit {
      color: $color-red;

      &::after {
        background-color: rgba($color-red, 0.5);
      }
    }

    > p,
    label {
      @include font(10px, 12px, 400);

      text-transform: uppercase;
      color: $color-red;
      margin: 0px;
      margin-top: 5px;
    }

    > label {
      margin: 0 0 10px;
    }
  }

  .suffix {
    @include flex(row, flex-start, center);
    @include size(fit-content);

    padding-right: 12px;

    > * + * {
      margin-left: 6px;
    }

    &__optional-label {
      @include font(12px, 14px, 400);

      pointer-events: none;
      position: relative;
      color: var(--color-text-secondary);
    }
  }
}
