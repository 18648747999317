@use '~scss/settings' as *;

.header {
  @include flex(row, space-between, center);
  max-height: 8%;
  color: var(--color-text);
  background-color: var(--color-background-container);
  padding: 12px 20px;
  box-shadow: $box-shadow;

  svg {
    color: $color-primary-orange;
  }

  nav {
    @include flex-gap(12px);
  }
}
