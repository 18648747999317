@use '~scss/settings' as *;

.display-activity {
  background-color: $color-white;
  border-radius: $border-radius;
  position: relative;
  color: var(--color-text);
  min-height: 350px;

  > ul {
    > li {
      @include flex(row, space-between);

      padding: 8px 4px;
      border-bottom: 1px solid currentColor;

      &:last-child {
        border-bottom: none;
      }

      > span {
        &:first-child {
          font-weight: 500;
        }
      }
    }
  }
}
.ncoded-modal.overlay {
  .ncoded-modal__content {
    main {
      position: relative;
    }
  }
}
