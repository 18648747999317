@use '~scss/settings' as *;

.navigation-wrapper {
  @include flex(column);
  @include size(100%);

  &__content-container {
    height: 100%;
    flex: 1;
    overflow: hidden;
    padding: 50px;
  }

  &__row {
    @include flex;

    flex: 1;

    > .eden-navigation {
      flex: 0 0 auto;
    }
  }
}
