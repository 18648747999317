@use '~scss/settings' as *;

.login {
  @include flex(row, center, center);
  @include size(100%);

  // background: radial-gradient(
  //   closest-side,
  //   $color-primary,
  //   $color-primary,
  //   var(--color-secondary),
  //   transparent,
  //   var(--color-secondary),
  //   $color-primary
  // );

  background: linear-gradient(var(--color-secondary), $color-primary);

  &__card {
    @include size(350px);
    @include flex(column, center, center);

    color: var(--color-primary);

    > a {
      @include font(14px, 18px, 400);

      text-align: center;
      width: 100%;
      text-decoration: none;
      padding: 12px;
      margin-top: 16px;
      background-color: var(--color-text);
      cursor: pointer;
      color: var(--color-primary);
      border-radius: $border-radius;

      :hover {
        cursor: pointer;
      }
    }
  }
}
